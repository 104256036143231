<template>
  <div v-loading="loading" class="collection-detail-wrapper">
    <div class="content-title-area">
      <p class="title">
        集采项目编号： {{ detailsObj.projectNo }}<Status :status="Number(detailsObj.projectStatus)" :status-list="projectStatus" :status-map="collectionStatus" />
      </p>
      <p v-if="detailsObj.projectStatus <= 2" class="project-time">
        距离集采终止 <span>{{ projectEndDate }}</span>
      </p>
    </div>
    <div class="collection-statistics">
      <div class="content-title-area">
        <p class="title">
          集采项目统计
        </p>
        <el-button type="primary" link size="small" @click="gotoCustomerDetail">
          查看统计明细
        </el-button>
      </div>
      <div class="statistics">
        <div v-for="(item, index) in projectStatistics" :key="index" class="statistics-item">
          <img :src="item.src" alt="">
          <div>
            <p>{{ item.label }}<span v-if="index >= 4">({{ $codeNew(meterageUnitOptions, item.meterageUnit) }})</span></p>
            <p>{{ item.value }} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="collection-detail">
      <Details-page
        :details-title="'集采信息'"
        :detail-obj="detailsObj"
        :details-item-arr="detailsItemArr"
        :item-data="itemData"
        :list-data="listData"
      />
      <p class="collection-detail-info">
        <span>其他指数参数</span>{{ detailsObj.otherIndicatorParameters }}
      </p>
    </div>
    <div class="contract">
      <div class="content-title-area">
        <p class="title">
          上游供货合同
        </p>
      </div>
      <div v-if="detailsObj.purchaseContractUrl" class="contract-top">
        <p><span>供货方</span> <span>{{ detailsObj.supplier }}</span></p>
        <p><span>上游合同编号</span><span>{{ detailsObj.supplierContractNo }}</span></p>
      </div>
      <div v-if="detailsObj.purchaseContractUrl" class="contract-list">
        <img src="@/assets/image/fileType1.png" alt="">
        <div>
          <div class="contract-list-down">
            <p>{{ detailsObj.purchaseContractName }}</p>
            <el-button
              type="primary"
              link
              @click="downloadPdf(
                $fileUrl + detailsObj.purchaseContractUrl,
                detailsObj.purchaseContractName
              )"
            >
              下载
            </el-button>
            <a
              :href="$fileUrl + detailsObj.purchaseContractUrl"
              target="_blank"
              style="margin-left: 12px;"
            ><el-button type="primary" link>查看</el-button></a>
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        <img src="@/assets/image/nodata4.png" alt="">
        <p>暂无文件～</p>
      </div>
    </div>
  </div>
</template>
<script>
import { getSelectAreaTree } from '@/api/login'
import { getCollectionDetail } from '@/api/supplyChain'
import { countDownFn } from '@/utils/index'
import { downloadFile } from '@/utils/util'
import Status from '../components/collectionStatus.vue'
import DetailsPage from '@/components/DetailsPage.vue'
import { projectStatistics, collectionStatus } from '../menu'
export default {
  components: { Status, DetailsPage },
  data() {
    return {
      timer: null,
      projectEndDate: '',
      loading: false,
      allCityOption: [],
      projectStatistics,
      collectionStatus,
      detailsObj: {},
      listData: [],
      meterageUnitOptions: this.$store.getters.getDictionaryItem('purchaseUnit'),
      projectStatus: this.$store.getters.getDictionaryItem('COLLECTION_PROJECT_STATE'),
      detailsItemArr: [
        { label: '集采项目名称', value: 'projectName' },
        { label: '创建时间', value: 'createTime' },
        { label: '报名截止时间', value: 'enterEndDate' },
        { label: '尾款截止时间', value: 'balancePaymentEndDate' },
        { label: '集采终止时间', value: 'projectEndDate' },
        { label: '保证金', value: 'depositValue' },
        { label: '交收方式', value: 'settlementStyle', child: [{ val: '过户', id: 1 }] },
        { label: '提货仓库', value: 'deliveryWarehouseName' },
        { label: '交货期限', value: 'deliveryPeriod' },
        { label: '联系人', value: 'linkMan' },
        { label: '联系电话', value: 'linkPhone' },
        { label: '微信咨询', value: 'wechatNumber' },
        { label: '交货地址', value: 'address', span: 2 }
      ],
      itemData: [{ label: '品名', prop: 'threeName', width: 200 },
        { label: '材质', prop: 'goodsMaterials', width: 180 },
        { label: '规格', prop: 'goodsSpecs', width: 220 },
        { label: '产地', prop: 'goodsProduct', width: 180 },
        { label: '件重', prop: 'goodsWeights', width: 180 },
        { label: '预计集采总量', prop: 'centralizedPurchaseTotalQuantity', width: 140 },
        { label: '最小认购量', prop: 'minSubscribeQuantity', width: 140 },
        { label: '计量单位', prop: 'meterageUnit', width: 160, child: this.$store.getters.getDictionaryItem('purchaseUnit') },
        { label: '集采单价', prop: 'centralizedPurchasePrice', type: 'money', Symbol: true, width: 180 },
        { label: '当前市场价', prop: 'currentMarketPrice', width: 140, type: 'money', Symbol: true }
      ]
    }
  },
  created() {
    this.loading = true
    // 获取所有的城市
    getSelectAreaTree('00', areaRes => {
      this.allCityOption = [...areaRes.data]
      getCollectionDetail(this.$route.query.projectId, res => {
        this.detailsObj = res.data
        this.detailsObj.enterEndDate = `${res.data.enterEndDate} 23:59`
        this.detailsObj.balancePaymentEndDate = `${res.data.balancePaymentEndDate} 23:59`
        this.detailsObj.projectEndDate = `${res.data.projectEndDate} 23:59`
        this.detailsObj.depositValue = res.data.depositValue + (res.data.depositType === 1 ? '%比例' : '元定额')
        this.detailsObj.deliveryPeriod = `合同签订后${res.data.deliveryPeriod}天日提货，超期后需自行线下支付仓储费`
        this.allCityOption.map(provice => {
          if (provice.code === res.data.placeProvince) {
            this.detailsObj.address = `${provice.name}`
            provice.children && provice.children.map(city => {
              if (city.code === res.data.placeCity) {
                this.detailsObj.address += `/${city.name}/`
                city.children && city.children.map(area => {
                  if (area.code === res.data.placeTown) {
                    this.detailsObj.address += `${area.name}` + (!res.data.placeDetailAddr ? '' : `/${res.data.placeDetailAddr}`)
                  }
                })
              }
            })
          }
        })
        this.listData.push({
          threeName: `${res.data.categoryName}/${res.data.varietyName}/${res.data.goodsName}`,
          goodsName: res.data.goodsName,
          materials: res.data.materials,
          productions: res.data.productions,
          categoryName: res.data.categoryName,
          goodsMaterials: res.data.goodsMaterials,
          goodsSpecs: res.data.goodsSpecs,
          goodsProduct: res.data.goodsProduct,
          skuId: res.data.skuId,
          goodsWeights: res.data.goodsWeights,
          centralizedPurchaseTotalQuantity: res.data.centralizedPurchaseTotalQuantity,
          minSubscribeQuantity: res.data.minSubscribeQuantity,
          meterageUnit: res.data.meterageUnit,
          centralizedPurchasePrice: res.data.centralizedPurchasePrice,
          currentMarketPrice: res.data.currentMarketPrice
        })
        this.projectStatistics.map(item => {
          item.value = res.data[item.key] || 0
          item.meterageUnit = res.data.meterageUnit
        })
        console.log(res.data.projectEndDate)
        this.timer = setInterval(() => {
          this.projectEndDate = countDownFn(`${res.data.projectEndDate}:59`)

          this.loading = false
        }, 1000)
      })
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    gotoCustomerDetail() {
      this.$router.push({
        path: '/collectionProject/customerDetail',
        query: {
          projectId: this.$route.query.projectId,
          meterageUnit: this.detailsObj.meterageUnit
        }
      })
    },
    downloadPdf(url, name) {
      downloadFile(url, name)
    }
  }
}
</script>

<style lang="scss">
    .collection-detail-wrapper {
        background: transparent;
        >.content-title-area {
            padding-left: 0;
        }
        .el-descriptions-item__label{
          display: inline-block;
          width: 96px;
        }
        .content-title-area {
            padding-right: 24px;
        }
        .content-title-area .title span {
            padding: 4px;
            width: 50px;
            background: #ccc;
            color: red;
        }
        .project-time {
            color: #F13C14;
            font-size: 18px;
        }
        .collection-statistics {
            background: #fff;
            margin-bottom: 16px;
            .statistics {
                margin: 10px 24px 24px;
                display: flex;
                justify-content: space-between;
                &-item {
                    background: #F8F8F8;
                    // border: 1px solid #ccc;
                    display: flex;
                    // flex-direction: column;
                    align-items: center;
                    padding: 20px 36px 20px 24px;
                    img {
                        padding-right: 10px;
                    }
                    p:first-child {
                        font-size: 14px;
                        margin-bottom: 5px;
                        color: #999;
                    }
                    p:nth-of-type(2) {
                        font-size: 28px;
                        font-weight: 500;
                    }
                }
            }
        }
        .collection-detail {
            background: #fff;
            &-info {
                margin: 20px 50px 20px;
                span:nth-of-type(1) {
                    color: #999999;
                    margin-right: 24px;
                }
            }
        }
        .contract {
            margin-top: 20px;
            background: #fff;
            padding-bottom: 24px;
            &-top {
                display: flex;
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
                p {
                    margin-right: 80px;
                    span:nth-of-type(1) {
                        color: #999;
                        margin-right: 24px;
                    }
                }
                p:nth-of-type(2) {
                    margin: auto;
                }
            }
            &-list {
                display: flex;
                align-items: center;
                padding-left: 20px;
                margin: 20px 0;
                > img {
                    width: 24px;
                    height: 24px;
                    margin-right: 12px;
                }
                &-down {
                    display: flex;
                    p {
                        margin-right: 40px;
                    }
                }
            }
            .no-data {
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    width: 108px;
                    height: 108px;
                }
                p {
                   font-size: 14px;
                   color: #999999;
                }
            }
        }
    }
</style>
