/** *
 * 操作数据对象 用formItem里的select组件时需要来这里处理下数据
 * @param list
 * @param name
 * @param code
 * @returns {Array|any}
 */
export function operateArrayObj(list, name, code) {
  const namePattern = eval('/' + name + '/g')
  const codePattern = eval('/' + code + '/g')
  if (list && list.length > 0) {
    return JSON.parse(JSON.stringify(list).replace(namePattern, 'name').replace(codePattern, 'code'))
  }
  return []
}
/**
 * 解决两个数相乘精度丢失问题
 * @param a
 * @param b
 * @returns {Number}
 */
export function floatMul(a, b) {
  var c = 0
  var d = a.toString()
  var e = b.toString()
  c += d.split('.')[1].length
  return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
}
// 倒计时函数
export function countDownFn(target, returnStr) {
  // 获取当前时间
  var date = new Date()
  // 获取中秋时间
  var tgt = new Date(target)
  // 获取时间差
  var distance = tgt - date
  if (distance <= 0) {
    // alert("目标时间不能小于当前时间");
    return
  }

  // 计算剩余时间
  var d = Math.floor(distance / (1000 * 60 * 60 * 24))
  var h = Math.floor((distance / (1000 * 60 * 60)) % 24)
  var m = Math.floor((distance / (1000 * 60)) % 60)
  var ms = Math.floor(distance / 1000 % 60)
  var time =
    d.toString().padStart(2, '0') +
    '天' +
    h.toString().padStart(2, '0') +
    '时' +
    m.toString().padStart(2, '0') +
    '分' +
    ms.toString().padStart(2, '0')

  return !returnStr ? time : { day: d.toString().padStart(2, '0'), hour: h.toString().padStart(2, '0'), minutes: m.toString().padStart(2, '0'), second: ms.toString().padStart(2, '0') }
}
